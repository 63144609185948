.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_container {
    width: 450px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: #d3d3d3;
    padding: 20px;
    border-radius: 8px;
}

.title_container {
    margin-bottom: 40px;
    text-transform: uppercase;
}

h1 {
    letter-spacing: 4px;
}

span {
    margin: 4px;
}

.googleRed {
    color: #db4437;
}

.googleBlue {
    color: #0f9d58;
}

.googleGreen {
    color: #4285f4;
}
