.user {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.user_container {
    width: 700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: #d3d3d3;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 6px 6px 5px lightslategray;
    margin-bottom: 20px;
}

.user_btn {
    width: 150px;
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: #03be9f;
    border-radius: 8px;
}

.user div {
    margin-top: 7px;
}

.user h1 {
    color: #03be9f;
}