.labels_container {
    width: 700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: #d3d3d3;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 6px 6px 5px lightslategray;
    margin-bottom: 20px;
}

.label {
    width: 250px;
    height: 40px;
    border-radius: 4px;
    margin: 20px 10px;
    padding: 2px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4f1f1;
    cursor: grab;
    border-left: 8px solid #fff;
}

.label:hover {
    border-left: 8px solid #03be9f;
}

.dropTarget {
    background-color: #e8f0fe;
}

.draggingSource {
    opacity: .3;
}